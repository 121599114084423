import "owl.carousel"
import moment from 'moment'
import "magnific-popup"

jQuery(document).ready(function ($) {

  // // Mobile Navigation
  $("#mobile-menu-icon").on('click', function (e) {
    e.preventDefault();
    $("#navbar").toggleClass("active");
    $("#link-wrapper").toggleClass("active");
    $("#mobile-menu-icon").toggleClass("active");
  });

  // Products & Buylinks (build music carousel item)
  $.ajax({
    url: 'https://services.sme-apps.com/cache/?id=74&lang=de_DE&type=products',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching Beast Socials!');
    },
    success: data => {
      const products = data.products
      const musicItem = $('#music-carousel')

      if (products.length) {
        let html = ''

        for (let i = 0; i < products.length; i++) {
          const coverArt = products[i].cover.url
          const altText = products[i].title
          const buyLinks = products[i].buy_links
          const artist = products[i].artist
          let spotifyEmbedId = artist.spotify
          let buyLink = ""
          let spotify = false

          buyLinks.forEach(item => {
            if (item.merchant.name == "Spotify") {
              spotify = true
              spotifyEmbedId = item.url
            }

            if (item.merchant.name == "Linkfire") {
              buyLink = item.url
            }

          })

          html += '<div class="item">'
          html += '<div class="cover">'
          html += `<img src="${coverArt}" alt="${altText}">`
          html += '</div>'
          html += '<div class="music">'
          html += `<iframe style="border-radius:12px" src="${spotifyEmbedId}" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`

          if (buyLink) {
            html += `<a class="btn" href="${buyLink}" target="_blank">JETZT BESTELLEN & ANHÖREN</a>`
          }

          html += '</div>'
          html += '</div>'
        }

        if (musicItem.length) {
          musicItem.html(html)
        }
      }

      // Music Carousel
      $('#music-carousel').owlCarousel({
        loop: true,
        nav: true,
        dots: false,
        items: 1,
        startPosition: 1,
        center: true,
        navText: [
          '<div class="owl-custom-nav-prev arrow"><i class="fas fa-arrow-circle-left"></i></div>',
          '<div class="owl-custom-nav-next arrow"><i class="fas fa-arrow-circle-right"></i></div>'
        ]
      })
    }
  })

  // Social/DSP Links Import and MUSIC CAROUSEL
  $.ajax({
    url: 'https://services.sme-apps.com/cache/?id=74&lang=de_DE&type=artists',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching Beast Socials!');
    },
    success: (data) => {

      // Social
      const artist = data.artists[0]
      const socials = $('#beast-socials')
      const footerSocials = $('#footer-socials')
      let html = ''

      if (artist.spotify) {
        const spotify = artist.spotify
        html += `<li><a href="${spotify}" target="_blank"><img alt="spotify" src="./dist/img/icon/spotify.svg"></a></li>`
      }

      if (artist.itunes) {
        const itunes = artist.itunes
        html += `<li><a href="${itunes}" target="_blank"><img alt="itunes" src="./dist/img/icon/apple.svg"></a></li>`
      }

      if (artist.amazon) {
        const amazon = artist.amazon
        html += `<li><a href="${amazon}" target="_blank"><img alt="amazon" src="./dist/img/icon/amazon.svg"></a></li>`
      }

      if (artist.tiktok) {
        const tiktok = artist.tiktok
        html += `<li><a href="${tiktok}" target="_blank"><img alt="tiktok" src="./dist/img/icon/tiktok.svg"></a></li>`
      }

      if (artist.instagram) {
        const instagram = artist.instagram
        html += `<li><a href="${instagram}" target="_blank"><img alt="instagram" src="./dist/img/icon/instagram.svg"></a></li>`
      }

      if (artist.facebook) {
        const facebook = artist.facebook
        html += `<li><a href="${facebook}" target="_blank"><img alt="facebook" src="./dist/img/icon/facebook.svg"></a></li>`
      }

      if (artist.youtube) {
        const youtube = artist.youtube
        html += `<li><a href="${youtube}" target="_blank"><img alt="youtube" src="./dist/img/icon/youtube.svg"></a></li>`
      }

      // Newsletter
      if (artist.newsletter) {
        const navNewsletterLink = $('.newsletter-link')
        let navHtml = ''
        const newsletter = artist.newsletter

        navHtml += `<a class="mfp-iframe" href="${newsletter}">Newsletter</a>`
        navNewsletterLink.html(navHtml)

        html += `<li><a class="mfp-iframe" href="${newsletter}"><img alt="newsletter" src="./dist/img/icon/newsletter.png"></a></li>`
      }

      if (html.length) {
        socials.html(html)
        footerSocials.html(html)
      }

      //popup
      $(".mfp-iframe").magnificPopup({
        type: "iframe"
      })

      // Shop
      if (artist.shop) {
        const shopBtn = $('#shop-button')
        const shopUrl = artist.shop
        let shopHtml = ''

        shopHtml += `<a class="btn" href="${shopUrl}">Zum Shop</a>`

        shopBtn.html(shopHtml)
      }


      // Navlinks
      if (artist.merch) {
        const url = artist.merch
        const navBtn = $('#treffe-link')
        let navHtml = ''
        navHtml += `<a href="${url}" target="_blank" >Treffe Die Dinos</a>`
        navBtn.html(navHtml)

        const footerLink = $('#treffe-footer-link')
        footerLink.attr('href', url)
      }

      if (artist.myspace) {
        const url = artist.myspace
        const navBtn = $('#fanclub-link')
        let navHtml = ''
        navHtml += `<a href="${url}" target="_blank" >Fanclub</a>`
        navBtn.html(navHtml)

        const fanclubFooterLink = $('#fanclub-footer-link')
        fanclubFooterLink.attr('href', url)
      }
    }
  })

  // Tourdate Import
  jQuery(document).ready(function ($) {
    $.ajax({
      url: 'https://services.sme-apps.com/cache/?id=74&lang=de_DE&type=events',
      method: 'GET',
      dataType: 'json',
      error: () => {
        console.log('error!');
      },
      success: (response) => {
        if (response.event_dates.length) {
          let tours = [];
          for (let event of response.event_dates) {
            let i = tours.findIndex(e => e.name === event.tour.name);
            if (i < 0) {
              tours.push({
                name: event.tour.name,
                events: []
              });
              i = tours.length - 1;
            }
            tours[i].events.push(event);
          }
          // console.log(tours)

          if (tours.length) {
            createTourHTML(tours)
          }
        }
      }
    })
  })

  function createTourHTML(tours) {

    const tourSection = $("#tour-section")
    let html = ""


    for (let tour of tours) {
      moment.locale('de')
      html += `<div class="tour"><h3>${tour.name}</h3><div class="tour-dates">`

      for (let show of tour.events) {
        html += '<div class="event-group">'
        html += '<div class="event-info">'
        html += `<div class="event-date">${moment(show.start_date).format('DD. MMMM YYYY')}</div>`
        html += `<div class="event-venue">${show.location}</div> | `
        html += `<div class="event-location">${show.city}</div>`
        html += '</div>'
        html += '<div class="event-links">'

        for (let ticket of show.ticket_links) {
          html += `<a class="link" href="${ticket.url} target="_blank" >${ticket.merchant.name}</a>`
        }

        html += '</div>'
        html += '</div>'
      }
      html += '</div></div>'
    }

    $("#tour-section").html(html)
  }

  // // Scroll
  $('.scroll').on('click', function (e) {
    e.preventDefault();

    const href = $(this).attr('href');
    const targetOffset = $(href).offset().top;
    const animationDuration = 500;

    $(".navbar").toggleClass("active");
    $('html, body').animate({
      scrollTop: targetOffset
    },
      animationDuration
    )
  });

  // YOUTUBE API and VIDEO CAROUSEL
  $.ajax({
    url: 'https://services.sme-apps.com/cache/?id=74&lang=de_DE&type=artists',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching Beast Artist Data!')
    },
    success: function (response) {
      const playlistUrl = response.artists[0].youtube_playlist

      getPlaylist(playlistUrl)
    },
    error: function (error) {
      console.error(error);
    }
  })

  function getPlaylist(url) {
    const regex = /[&?]list=([^&]+)/i
    const match = url.match(regex)
    const playlistId = match ? match[1] : ""
    const API_KEY = "AIzaSyCPAO_b_h1shtFFkIjaAJduaNq2vN_dvvk"
    const baseUrl = "https://www.googleapis.com/youtube/v3/"
    let html = ""

    $.ajax({
      url: `${baseUrl}playlistItems?part=snippet&key=${API_KEY}&playlistId=${playlistId}`,
      type: 'GET',
      dataType: 'text',
      success: (response) => {
        const responseJson = JSON.parse(response)

        for (let item of responseJson.items) {
          const title = item.snippet.title
          const videoId = item.snippet.resourceId.videoId

          html += `<div class="item"><div class="embed-container"><iframe width="560" height="315" data-src="https://www.youtube.com/embed/${videoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div><p>${title}</p></div>`
        }

        createVideoCarousel(html)
      },
      error: (error) => {
        console.error(error)
      }
    })
  }

  // VIDEO CAROUSEL
  function createVideoCarousel(html) {
    const videoCarousel = $("#videos-carousel")
    videoCarousel.html(html)
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fas fa-arrow-circle-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fas fa-arrow-circle-right"></i></div>'

    $('#videos-carousel').owlCarousel({
      loop: true,
      // stagePadding: 300,
      // margin: 20,
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      center: true,
      responsive: {
        0: {
          stagePadding: 50
        },
        700: {
          stagePadding: 120
        },
        1100: {
          stagePadding: 260
        }
      },
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }

  // ISO 8601 Decoder
  function durationToSeconds(durationString) {
    const durationRegex = /P(?:([0-9]+)Y)?(?:([0-9]+)M)?(?:([0-9]+)W)?(?:([0-9]+)D)?(?:T(?:([0-9]+)H)?(?:([0-9]+)M)?(?:([0-9]+)S)?)?/;
    const match = durationRegex.exec(durationString);

    let seconds = 0;
    let secondsPerMinute = 60;
    let minutesPerHour = 60 * secondsPerMinute;
    let hoursPerDay = 24 * minutesPerHour;
    if (match) {
      const [, years, months, weeks, days, hours, minutes, secondsStr] = match;

      seconds += years ? parseInt(years) * 365 * hoursPerDay : 0;
      seconds += months ? parseInt(months) * 30 * hoursPerDay : 0;
      seconds += weeks ? parseInt(weeks) * 7 * hoursPerDay : 0;
      seconds += days ? parseInt(days) * hoursPerDay : 0;
      seconds += hours ? parseInt(hours) * minutesPerHour : 0;
      seconds += minutes ? parseInt(minutes) * secondsPerMinute : 0;
      seconds += secondsStr ? parseInt(secondsStr) : 0;
    }

    return seconds;
  }
})

// Blocks Iframes EU Compliance (germany)
coreFunctions.init({
  copyright: ['copyright', 'de'], //Container, Language
  enablethirdpartycleaner: true, // enable or disable the Iframe blocker
  tpcDomainsToBlock: [ // list of domains you want to block for this website
    "youtube.com",
    "youtube-nocookie.com",
    "widget.bandsintown.com",
    "www.eventim.de",
    "eventim.de",
    "forms.sonymusicfans.com",
    "www.tiktok.com",
    "tiktok.com",
    "connect.facebook.net",
    "facebook.net",
    "embed.music.apple.com",
    "maps.googleapis.com"
  ],
  thirdpartycleaner: { // some basic styling
    backgroundColor: "rgba(0,0,0,0.8)", // background color of the disclaimer
    textColor: "white", // text color of the disclaimer
    buttonBackgroundColor: "white", // background color of the allow button
    buttonTextColor: "black" // text color of the allow button
  }

})